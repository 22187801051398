import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";

import Layout from "../components/Layout";
import { ContentWrapper } from "../assets/styles/base";
import MetaComponents from "../components/seo/MetaComponents";

const Error = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <StyledContentWrapper>
                <h1 className="h1">404</h1>
                <h3>
                    <Trans>page.content.pageNotFound</Trans>
                </h3>
            </StyledContentWrapper>
        </Layout>
    );
};

const StyledContentWrapper = styled(ContentWrapper)`
    padding-bottom: 150px;
    padding-top: 100px;
`;

export default Error;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { ns: { in: ["header", "footer", "page.404"] }, language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
